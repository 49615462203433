.PhoneInput {
  height: 48px;
  border: 1px solid #e3e5e5;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.PhoneInput:hover{
  border: 1px solid #990012;
}
.PhoneInput input {
  border: none;
  color: #090a0a;
  font-family: Lato;
  font-size: 16px;
  line-height: 16px;
}
.PhoneInput input:hover {
  outline: none;
}
.PhoneInput input:focus {
  outline: none;
}
.PhoneInput .PhoneInputCountryIcon {
  width: 22px;
  height: 16px;
  border-radius: 3px;
  overflow: hidden;
  background-color: transparent;
  box-shadow: none;
}
