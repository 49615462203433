.chirz-slider .slick-arrow {
  display: none !important;
}
.chirz-slider .slick-slide {
  scale: 0.9;
  transition: scale 0.3s ease-in;
}
.chirz-slider .slick-active {
  scale: 0.97;
}
