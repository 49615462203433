a {
  color: "#990012";
  text-decoration: none;
}
.linkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff8f5;
  text-decoration: none;
  background: linear-gradient(to right top, #090a0a, #990012);
  width: 100%;
  min-width: 112px;
  max-width: 327px;
  border-radius: 48px;
  height: 48px;
  font-family: "Lato";
  font-size: "14px";
  line-height: "16px";
  font-weight: 300;
}
.linkButton:hover {
  color: #990012;
  background: #e3e5e5;
}
.linkButtonLight {
  color: #990012;
  background: #fff;
}
.linkButtonLight:hover {
  color: #fff8f5;
  background: linear-gradient(to right top, #090a0a, #990012);
}
