
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("./assets/fonts/sackersGothicStd/stylesheet.css");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.object-contain {
  object-fit: contain;
  object-position: center;
}
.object-cover {
  object-fit: cover;
  object-position: center;
}
