.login {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
}
